import React, { useEffect, useState } from "react";

import "./Showcase.scss";
import aquascopeUrl from "./assets/aquascope.webp?url";
import argusUrl from "./assets/argus.webp?url";
import siteArchiveUrl from "./assets/home-archive.webp?url";
import spookyStoriesUrl from "./assets/spooky-stories.webp?url";
import thePlacesYouLiveUrl from "./assets/the-places-you-live.webp?url";
import blogUrl from "./assets/writings.webp?url";
import {
  debounce,
  getWindowDimensions,
  hexS,
  local,
  pythagorean
} from "./funs";
import TiledHexagons from "./tiled-hexagons/tiled-hexagons";

// Where the content is coming from
type Source = ["image", string] | ["web", string] | ["component", React.FC];

const TILE_SIZE = 100;

// NOTE: taken from "variables.module.scss", the node-sass
// imports are not working.
const lightOrange = "#FCB900";

interface ItemDescription {
  source: Source;
  url?: string;
  title: string;
  subTitle?: string;
}

const items: ItemDescription[] = [
  {
    source: ["image", argusUrl],
    url: "https://github.com/cognitive-engineering-lab/argus",
    title: "Argus",
    subTitle: "An experimental type-level debugger for Rust traits"
  },
  {
    source: ["image", aquascopeUrl],
    url: "https://cognitive-engineering-lab.github.io/aquascope",
    title: "Aquascope",
    subTitle: "Type system visualizer for Rust ownership"
  },
  {
    source: ["image", thePlacesYouLiveUrl],
    url: local("the-places-you-live"),
    title: "The Places You’ll Live",
    subTitle: "Playing with MapboxGL to visualize where I’ve lived"
  },
  {
    source: ["image", spookyStoriesUrl],
    url: local("spooky-stories"),
    title: "Spooky Stories",
    subTitle: "Experimenting with WebGL text effects to enhance storytelling"
  },
  {
    source: ["image", blogUrl],
    url: local("writings"),
    title: "Personal Writing",
    subTitle:
      "The best part about being an adult is saying nothing when there’s nothing to say"
  },
  {
    source: ["image", siteArchiveUrl],
    url: local("home-archive"),
    title: "Past Websites",
    subTitle: "A collection of past websites, for posterity"
  }
];

const ImageItem = ({
  src,
  height,
  alt
}: { src: string; height: number; alt: string }) => {
  return <img height={height} width="auto" src={src} alt={alt} />;
};

const SiteItem = ({ url, alt }: { url: string; alt: string }) => {
  const [loaded, setIsLoaded] = useState(false);
  const onLoaded = () => {
    setInterval(
      debounce(() => setIsLoaded(true), 500),
      500
    );
  };

  return (
    <div className="thumbnail-container">
      <div className="thumbnail">
        <iframe
          title={alt}
          className={loaded ? "loaded" : ""}
          src={url}
          onLoad={onLoaded}
        />
      </div>
    </div>
  );
};

const Item = ({ item, height }: { item: ItemDescription; height: number }) => {
  const [kind, Elem] = item.source;
  const alt = `${item.title}: ${item.subTitle ?? ""}`;
  const [link, content] =
    kind === "image"
      ? [item.url!, <ImageItem src={Elem} height={height} alt={alt} />]
      : kind === "web"
        ? [Elem, <SiteItem url={Elem} alt={alt} />]
        : [item.url!, <Elem />];

  return (
    <div className="item">
      <a href={link} target="_blank" rel="noreferrer">
        <div className="container">
          {content}
          <div className="overlay">
            <div className="text">
              <h2>{item.title}</h2>
              <p>{item.subTitle}</p>
            </div>
          </div>
        </div>
      </a>
    </div>
  );
};

const Showcase = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const sideLength = Math.min(TILE_SIZE, windowDimensions.width / 6);
  const hexOffset = pythagorean(sideLength, hexS(sideLength) / 2);
  const imgHeight = sideLength + 2 * hexOffset;

  const tiles = items.map((item, i) => {
    return {
      Content: () => <Item key={i} item={item} height={imgHeight} />,
      shadow: lightOrange
    };
  });

  return (
    <div id="showcase-container">
      <div id="showcase">
        <h2>Fun Projects</h2>
        <TiledHexagons
          tileSideLengths={sideLength}
          tileGap={5}
          tileBorderRadii={8}
          maxHorizontal={3}
          tiles={tiles}
        />
      </div>
    </div>
  );
};

export default Showcase;
