import React from "react";
//@ts-ignore
import Hyphenated from "react-hyphen";

const HyphenatedP = ({ children }: React.PropsWithChildren) => {
  return (
    <Hyphenated>
      <p>{children}</p>
    </Hyphenated>
  );
};

const Article = () => {
  return (
    <>
      <HyphenatedP>
        Greetings! I research the <i>human factors</i> of programming language
        design and want the next generation of programming languages to be more
        accessible.
      </HyphenatedP>

      <HyphenatedP>
        My research currently focuses on <b>Rust</b>, but I secretly enjoy
        programming with parentheses.
      </HyphenatedP>

      <HyphenatedP>
        If you’re interested in my reseach{" "}
        {/* take a stroll through my{" "}
        <a href={local("research-garden")}>“research studio”</a> and */}{" "}
        don’t hesitate to{" "}
        <a href="mailto:gavinleroy@brown.edu">send an email</a>.
      </HyphenatedP>
    </>
  );
};

export default Article;
